import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import View from './View';

const Membership2 = ({formContext, theme}) => {
  return <View formContext={formContext} theme={theme} textField={'membershipTextHtml_txt'} titleField={'membershipTitle'} />;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    theme: state.reducer.theme
  };
};

export default connect(mapStateToProps)(withRouter(Membership2));

