import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AmountButton from '../AmountButton';
import {Field} from '@nexios/frontend-forms';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 20px;

  div {
    justify-content: space-around;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Center = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: ${props => props.theme.buttonContainerSpaceBetween.width};
  }
`;

SpaceBetween.defaultProps = {
  theme: {
    buttonContainerSpaceBetween: {
      width: '94px'
    }
  }
}

export default class ButtonContainer extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired
  };

  render () {
    if (this.props.list === undefined) {
      return null;
    }

    const listItems = this.props.list.map((item, indx) => <Field name={this.props.fieldName} key={indx} component={
      ({setValue, validate, value: currentSelectedValue}) => <AmountButton
        isChecked={currentSelectedValue === item.value}
        label={item.label}
        value={item.value}
        ribbon={item.ribbon}
        icon={item.icon}
        title={item.title}
        validate={validate}
        setValue={setValue} />
    } />);

    if (listItems.length === 1) {
      return <Center>
        {listItems}
      </Center>;
    } else if (listItems.length === 3) {
      return <SpaceBetween>
        {listItems}
      </SpaceBetween>;
    } else if (listItems.length === 4) {
      return <MainContainer>
        <Container>{listItems[0]}{listItems[1]}</Container>
        <Container>{listItems[2]}{listItems[3]}</Container>
      </MainContainer>;
    } else {
      return <Container>
        {listItems}
      </Container>;
    }
  }
}
