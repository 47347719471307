import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import parseText from '../../utils/parseText';
import {Helmet} from 'react-helmet';
import MainContainer from '../../components/MainContainer';
import LayoutContainer from '../../components/LayoutContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import Box from '../../components/Box';
import SocialShare from '../../components/SocialShare';
import {getFormData} from '@nexios/frontend-forms';


const getThankYouTexts = (formContext, formData, theme) => {
  const showDonationFields = formData.showDonationFields;
  const isOneOff = formData.frequency === 'oneOff';
  const isDirectDebit = formData.paymentMethod === 'directDebit';

  let titleTemplate;
  let textTemplate;

  if (showDonationFields) {
    if (isOneOff) {
      if (isDirectDebit) {
        titleTemplate = formContext.oneOffThankYouTitle;
        textTemplate = formContext.oneOffThankYouTextHtml_txt;
      } else {
        titleTemplate = formContext.donationThankYouTitle;
        textTemplate = formContext.donationThankYouTextHtml_txt;
      }
    } else if (formContext.mandateType === 'temporary') {
      titleTemplate = formContext.temporaryThankYouTitle;
      textTemplate = formContext.temporaryThankYouTextHtml_txt;
    } else {
      titleTemplate = formContext.continuousThankYouTitle;
      textTemplate = formContext.continuousThankYouTextHtml_txt;
    }
  } else {
    titleTemplate = formContext.petitionThankYouTitle;
    textTemplate = formContext.petitionThankYouTextHtml_txt;
  }

  return [parseText(formData, titleTemplate), parseText({...formData, ...formContext}, textTemplate, theme)];
};


const Petition2 = ({formContext, formData, theme}) => {
  const [thankYouTitle, thankYouText] = getThankYouTexts(formContext, formData, theme);

  return <>
    <Helmet>
      <title>{formContext.thankYouBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<><Box>
        <div className='widgetContent'>
          <h1>{thankYouTitle}</h1>
          <div className='field'>
            <div dangerouslySetInnerHTML={{__html: thankYouText}} />
          </div>

          <SocialShare formContext={formContext} />
        </div>
      </Box>
        {formContext.allowCallToAction &&
          <div dangerouslySetInnerHTML={{__html: formContext.callToAction}} />}
      </>}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    formData: getFormData(state, 'donate'),
    theme: state.reducer.theme
  };
};

export default connect(mapStateToProps)(withRouter(Petition2));

