import _ from 'lodash';
import {getFormData, updateData} from '@nexios/frontend-forms';
import async from 'async';
import {
  CLEANSE_ADDRESS,
  DESTROY_SESSION,
  INIT,
  REDIRECT,
  CLEANSE_NAME,
  SAVE,
  SAVE_LEAD,
  SAVE_MEMBERSHIP,
  SAVE_PETITION,
  SAVE_TAXDEDUCTIBLE,
  setIsCleansing,
  STORE_REFERENCE_KEY,
  SUBSCRIBE_TO_OPT_IN,
  UPDATE_SESSION,
  updateSession as updateSessionFromReducer
} from '../reducer';
import {gaStep3} from '../googleAnalytics/step3';
import {gaNoRedirect, gaRedirect} from '../googleAnalytics/redirect';
import {gaStep4} from '../googleAnalytics/step4';

function sendTestInfo(data) {
  fetch('/api/split-test', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...data
    })
  })
    .then(data => {
      return;
    })
    .catch(err => {
      return;
    });
}

function post(path, params, method) {
  method = method || 'post'; // Set method to post by default if not specified.

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

function getConfig(callback) {
  const dmPv = /dm_pv=([^&]+)/i;
  const dmPvResult = dmPv.exec(window.location.href);
  const campaignPropositionRegex = /dm_cp=([^&]+)/i;
  const campaignPropositionCode = campaignPropositionRegex.exec(window.location.href);

  const dm_pv = _.get(dmPvResult, '1');
  const dm_cp = _.get(campaignPropositionCode, '1');

  fetch('/api/config', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      url: window.location.pathname.replace(/^\/([^/]+).*$/, '$1'),
      dm_pv,
      dm_cp
    })
  })
    .then((resp) => resp.json())
    .then(data => {
      callback(null, data);
    })
    .catch(err => {
      callback(err);
    });
}

function getSession(pageVariantKey, proposition, callback) {
  fetch('/api/getSession', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      pageVariantKey,
      proposition
    })
  })
    .then((resp) => resp.json())
    .then(data => {
      callback(null, data);
    })
    .catch(err => {
      callback(err);
    });
}

function updateSession(data, callback) {
  fetch('/api/updateSession', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((resp) => resp.json())
    .then(data => {
      callback(null, data);
    })
    .catch(err => {
      callback(err);
    });
}

function updateTelephoneData(data, formContext, formConfig) {
  if (formConfig.isTelephoneVisible === false) {
    delete data.telephone;
    delete data.allowTelemarketing;
  }

  if (data.telephone) {
    if (formContext.telemarketingOptIn === 'checkbox' && data.allowTelemarketing) {
      data.telemarketingOptInText = formContext.telemarketingCheckBoxText;
    } else if (formContext.telemarketingOptIn === 'text' || formContext.telemarketingOptIn === 'button') {
      data.allowTelemarketing = true;
      data.telemarketingOptInText = formContext.telemarketingInfoText;
    }
  } else {
    delete data.allowTelemarketing;
  }
}

export function doRedirect(formContext, data, history) {
  return {
    type: REDIRECT,
    formContext,
    data,
    history
  };
}

const apiMiddleware = store => next => action => {
  switch (action.type) {
    case INIT:
      getConfig((err, config) => {
        if (err) {
          console.log(err);
          return;
        }

        const pageVariantKey = _.get(config, ['pageVariantKey']);
        const proposition = _.get(config, ['proposition']);

        getSession(pageVariantKey, proposition, (err, session) => {
          if (err) {
            console.log(err);
            return;
          }

          const donationStateFields = ['isDonationCompleted', 'isStep1Completed', 'isStep2Completed', 'isStep3Completed', 'referenceKey', 'isPaying', 'isEmailOptInDone', 'isSmsOptInDone'];

          action.config = config;
          action.donationState = _.pick(session, donationStateFields);

          store.dispatch(updateData({
            isPaying: _.get(session, ['isPaying']),
            referenceKey: _.get(session, ['referenceKey']),
            instanceKey: 'donate',
            data: _.omit(session, donationStateFields),
            noOverwrite: false
          }));

          // do a redirect if payment is successful (isStep3Completed) and redirect is configured
          if (config.showThankYouPage === false && session.isStep3Completed) {
            store.dispatch(doRedirect(config, session));
          } else {
            next(action);
          }
        });
      });
      break;
    case UPDATE_SESSION:
      const state = store.getState();
      const formData = getFormData(state, 'donate');
      const pageVariantKey = _.get(state, ['reducer', 'formContext', 'pageVariantKey']);
      const proposition = _.get(state, ['reducer', 'formContext', 'proposition']);
      const data = _.assign({
        pageVariantKey,
        proposition
      }, action.donationState);

      next(action);

      _.each(action.fields, (field) => {
        data[field] = formData[field];
      });

      updateSession(data, (err) => {
        if (err) {
          console.log(err);
          return;
        }
        if (_.isFunction(action.callback)) {
          action.callback();
        }
      });
      break;
    case DESTROY_SESSION:
      fetch('/api/destroySession', {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((resp) => resp.json())
        .then(() => {
          const pathName = (window.location.pathname || '/').split('/');
          const dp_path = pathName[1];
          window.location = `/${dp_path}/${action.history.location.search}`;
        });
      break;
    case CLEANSE_ADDRESS: {
      const state = store.getState();
      if (state.reducer.isCleansing) return next(action);
      store.dispatch(setIsCleansing(true));
      fetch('/api/cleanseAddress', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(action.data)
      })
        .then((resp) => resp.json())
        .then(data => {
          store.dispatch(setIsCleansing(false));
          action.response = data;
          if (_.isFunction(action.callback)) {
            action.callback(data);
          }

          next(action);
        })
        .catch(err => {
          // TODO: dispatch error?
          console.log(err);
        });
      break;
    }
    case CLEANSE_NAME:
      fetch('/api/cleanseName', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(action.data)
      })
        .then((resp) => resp.json())
        .then(data => {
          action.response = data;
          if (_.isFunction(action.callback)) {
            action.callback(data);
          }

          next(action);
        })
        .catch(err => {
          // TODO: dispatch error?
          console.log(err);
        });
      break;
    case SAVE: {
      const state = store.getState();
      const formDataFromState = getFormData(state, 'donate');
      const formContext = _.get(state, ['reducer', 'formContext']);

      let data;
      if (action.submittedFormData) {
        data = action.submittedFormData;
      } else {
        data = formDataFromState;
        // only store the visible fields
        let formConfig;
        const donateAsOrganisation = formDataFromState.donateAsOrganisation;

        if (donateAsOrganisation) {
          formConfig = _.get(state, ['reducer', 'formContext', 'organisation']);
        } else {
          formConfig = _.get(state, ['reducer', 'formContext', 'person']);
        }

        if (formConfig.isTelephoneVisible === false) {
          delete data.telephone;
          delete data.allowTelemarketing;
        }

        if (data.telephone) {
          if (formContext.telemarketingOptIn === 'checkbox' && data.allowTelemarketing) {
            data.telemarketingOptInText = formContext.telemarketingCheckBoxText;
          } else if (formContext.telemarketingOptIn === 'text' || formContext.telemarketingOptIn === 'button') {
            data.allowTelemarketing = true;
            data.telemarketingOptInText = formContext.telemarketingInfoText;
          }
        } else {
          delete data.allowTelemarketing;
        }
      }

      data.currentUrl = window.location.href;
      data.campaignPropositionCode = _.get(formContext, ['proposition']);
      data.pageVariantKey = _.get(formContext, ['pageVariantKey']);
      data.variableFieldLabel = _.get(formContext, ['variableFieldLabel']);

      if (data.frequency !== 'oneOff' || data.paymentMethod === 'directDebit') {
        fetch('/api/saveMandate', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(responseData => {
            action.result = responseData;

            const referenceKey = responseData.referenceKey;

            store.dispatch({
              type: STORE_REFERENCE_KEY,
              referenceKey
            });

            store.dispatch(gaStep3(formContext));

            store.dispatch(updateSessionFromReducer(null, {
              isStep3Completed: true,
              referenceKey
            }, () => {
              store.dispatch(doRedirect(formContext, data, action.history));
            }));
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        // Redirect to paymentProvider
        fetch('/api/saveOnlinePayment', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(responseData => {
            action.result = responseData;

            const referenceKey = responseData.referenceKey;

            store.dispatch({
              type: STORE_REFERENCE_KEY,
              referenceKey
            });

            store.dispatch(gaStep3(formContext, referenceKey));

            const method = _.get(responseData, ['method']);

            if (method === 'POST') {
              const url = responseData.psp.PSPURL;
              delete responseData.psp.PSPURL;

              post(url, responseData.psp);
            } else {
              const url = responseData.REDIRECT_URL;
              window.location = url;
            }

            next(action);
          })
          .catch(err => {
            console.log(err);
          });
      }
      break;
    }
    case SAVE_TAXDEDUCTIBLE: {
      const state = store.getState();
      const data = getFormData(state, 'donate');
      data.currentUrl = window.location.href;
      data.campaignPropositionCode = _.get(state, ['reducer', 'formContext', 'proposition']);
      data.pageVariantKey = _.get(state, ['reducer', 'formContext', 'pageVariantKey']);
      const formContext = _.get(state, ['reducer', 'formContext']);

      // only store the visible fields
      let formConfig;
      const donateAsOrganisation = data.donateAsOrganisation;

      if (donateAsOrganisation) {
        formConfig = _.get(state, ['reducer', 'formContext', 'organisation']);
      } else {
        formConfig = _.get(state, ['reducer', 'formContext', 'person']);
      }

      if (formConfig.isTelephoneVisible === false) {
        delete data.telephone;
        delete data.allowTelemarketing;
      }

      if (data.telephone) {
        if (formContext.telemarketingOptIn === 'checkbox' && data.allowTelemarketing) {
          data.telemarketingOptInText = formContext.telemarketingCheckBoxText;
        } else if (formContext.telemarketingOptIn === 'text' || formContext.telemarketingOptIn === 'button') {
          data.allowTelemarketing = true;
          data.telemarketingOptInText = formContext.telemarketingInfoText;
        }
      } else {
        delete data.allowTelemarketing;
      }

      fetch('/api/saveDonationAgreementRequest', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(responseData => {
          action.result = responseData;

          const referenceKey = responseData.referenceKey;

          store.dispatch({
            type: STORE_REFERENCE_KEY,
            referenceKey
          });

          store.dispatch(updateSessionFromReducer(null, {
            isStep3Completed: true,
            referenceKey
          }, () => {
            store.dispatch(doRedirect(formContext, data, action.history));
          }));
        })
        .catch(err => {
          console.log(err);
        });
      break;
    }
    case SUBSCRIBE_TO_OPT_IN: {
      const postData = {
        proposition: action.campaignPropositionCode,
        pageVariantKey: action.pageVariantKey,
        email: action.email,
        telephone: action.telephone
      };
      fetch('/api/subscribeToOptIn', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      }).then(res => res.json())
        .then(() => {
          store.dispatch(updateSessionFromReducer(null, {
            isOptInDone: true
          }));
        })
        .catch(err => {
          console.log(err);
        });
      break;
    }
    case SAVE_LEAD: {
      const state = store.getState();
      const data = getFormData(state, 'donate');
      data.currentUrl = window.location.href;
      data.campaignPropositionCode = _.get(state, ['reducer', 'formContext', 'proposition']);
      data.pageVariantKey = _.get(state, ['reducer', 'formContext', 'pageVariantKey']);
      const formContext = _.get(state, ['reducer', 'formContext']);

      // only store the visible fields
      let formConfig;
      const donateAsOrganisation = data.donateAsOrganisation;

      if (donateAsOrganisation) {
        formConfig = _.get(state, ['reducer', 'formContext', 'organisation']);
      } else {
        formConfig = _.get(state, ['reducer', 'formContext', 'person']);
      }

      updateTelephoneData(data, formContext, formConfig);

      fetch('/api/saveLead', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(responseData => {
          action.result = responseData;

          const referenceKey = responseData.referenceKey;

          store.dispatch({
            type: STORE_REFERENCE_KEY,
            referenceKey
          });

          store.dispatch(updateSessionFromReducer(null, {
            referenceKey
          }, () => {
            store.dispatch(doRedirect(formContext, data, action.history));
          }));
        })
        .catch(err => {
          console.log(err);
        });
      break;
    }
    case SAVE_PETITION: {
      const state = store.getState();
      const formContext = _.get(state, ['reducer', 'formContext']);
      const data = {
        ...action.submittedFormData,
        currentUrl: window.location.href,
        campaignPropositionCode: _.get(formContext, ['proposition']),
        pageVariantKey: _.get(formContext, ['pageVariantKey'])
      };
      fetch('/api/savePetition', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(responseData => {
          action.result = responseData;

          const referenceKey = responseData.referenceKey;

          store.dispatch({
            type: STORE_REFERENCE_KEY,
            referenceKey
          });

          store.dispatch(updateSessionFromReducer(null, {
            referenceKey
          }, () => {
            store.dispatch(doRedirect(formContext, data, action.history));
          }));
        })
        .catch(err => {
          console.log(err);
        });
      break;
    }
    case SAVE_MEMBERSHIP: {
      const state = store.getState();
      const data = getFormData(state, 'donate');
      data.currentUrl = window.location.href;
      data.campaignPropositionCode = _.get(state, ['reducer', 'formContext', 'proposition']);
      data.pageVariantKey = _.get(state, ['reducer', 'formContext', 'pageVariantKey']);
      data.organisationUnitKey = _.get(state, ['reducer', 'formContext', 'organisationUnitKey']);
      data.membershipAgreementTypeKey = _.get(state, ['reducer', 'formContext', 'membershipAgreementTypeKey']);
      data.amount_m = _.get(state, ['reducer', 'formContext', 'amount_m']);
      data.frequency = _.get(state, ['reducer', 'formContext', 'frequency']);
      data.fundKey = _.get(state, ['reducer', 'formContext', 'fundKey']);
      const formContext = _.get(state, ['reducer', 'formContext']);

      // only store the visible fields
      let formConfig;
      const donateAsOrganisation = data.donateAsOrganisation;

      if (donateAsOrganisation) {
        formConfig = _.get(state, ['reducer', 'formContext', 'organisation']);
      } else {
        formConfig = _.get(state, ['reducer', 'formContext', 'person']);
      }

      updateTelephoneData(data, formContext, formConfig);

      fetch('/api/saveMembership', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(responseData => {
          action.result = responseData;

          const referenceKey = responseData.referenceKey;

          store.dispatch({
            type: STORE_REFERENCE_KEY,
            referenceKey
          });

          store.dispatch(updateSessionFromReducer(null, {
            referenceKey
          }, () => {
            store.dispatch(doRedirect(formContext, data, action.history));
          }));
        })
        .catch(err => {
          console.log(err);
        });
      break;
    }
    case REDIRECT: {
      const formContext = action.formContext;
      const showThankYouPage = _.get(formContext, ['showThankYouPage']);
      const redirectTo = _.get(formContext, ['redirectTo']);
      const data = action.data;

      if (showThankYouPage) {
        store.dispatch(gaNoRedirect());
        action.history.push(`/bedankt${window.location.search ? window.location.search : ''}`);
        // store isDonationCompleted after redirect so AlreadyCompleted is not rendered on the last page
        store.dispatch(updateSessionFromReducer(null, {
          isDonationCompleted: true
        }), () => {
          next(action);
        });
      } else {
        async.seq(
          fn => {
            store.dispatch(gaStep4(formContext, fn));
          },
          fn => {
            store.dispatch(gaRedirect(fn));
          }
        )(() => {
          fetch(redirectTo, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              Voornaam: data.firstName,
              Achternaam: data.lastName,
              Tussenvoegsel: data.infix,
              Voorletters: data.initials,
              'E-mailadres': data.email,
              Telefoonnummer: data.telephone,
              Geboortedatum: data.dateOfBirth,
              Geslacht: data.gender,
              Postcode: data.postalCode,
              Huisnummer: data.houseNumber_i,
              HuisnummerToevoeging: data.houseNumberAddition,
              Straat: data.street,
              Plaats: data.city,
              AlsOrganisatie: data.donateAsOrganisation,
              TelemarketingToestaan: data.allowTelemarketing,
              ToonVoorOrganisatie: data.renderAsOrganisation,
              ToonVoorPersoon: data.renderAsPerson,
              OrganisatieNaam: data.organisationName,
              VorigePaginaVariant: formContext.pageVariantName
            })
          }).then((data) => {
            window.location = data.url;
          })
            .catch(err => {
              console.log(err);
            });
        });
      }
      break;
    }
    case 'splitTest.send':
      sendTestInfo(action.body);
      break;
    default:
      return next(action);
  }
};

export default apiMiddleware;
