import React from 'react';
import {connect} from 'react-redux';
import parseText from '../../utils/parseText';

const parseDirectDebitText = (ctx, {mandateType, directDebitOneOff, directDebitOther, directDebitTemporary}) => {
  const notOneOffText = mandateType === 'temporary' ? directDebitTemporary : directDebitOther;
  const txt = ctx.frequency === 'oneOff' ? directDebitOneOff : notOneOffText;
  return parseText(ctx, txt);
};

const DirectDebitText = ({formContext, ...ctx}) => {
  if ((ctx.amount_m || ctx.customAmount_m) &&
    ctx.showDonationFields &&
    ctx.paymentMethod === 'directDebit') {
    return <div>{parseDirectDebitText(ctx, formContext)}</div>;
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    showDonationFields: state.form.donate_data_showDonationFields,
    amount_m: state.form.donate_data_amount_m,
    customAmount_m: state.form.donate_data_customAmount_m,
    frequency: state.form.donate_data_frequency,
    period: state.form.donate_data_period,
    paymentMethod: state.form.donate_data_paymentMethod
  };
};

export default connect(mapStateToProps)(DirectDebitText);
