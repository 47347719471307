import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {Provider, connect} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducer, {init} from './reducer';
import {formMiddleware} from '@nexios/frontend-forms';
import apiMiddleware from './middleware/apiMiddleware';
import Theme from './themes';
import GlobalStyle from './themes/GlobalStyles';

import './styles/_core.css';
import DonationRoutes from './DonationRoutes';
import DonationAnonRoutes from './DonationAnonRoutes';
import LeadRoutes from './LeadRoutes';
import MembershipRoutes from './MembershipRoutes';
import TaxDeductibleRoutes from './TaxDeductibleRoutes';
import PetitionRoutes from './PetitionRoutes';
import SendTestInformation from './components/SendTestInformation';

const startup = () => {
  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(thunk, apiMiddleware, formMiddleware)
  );

  const store = createStore(reducer, enhancer);

  store.dispatch(init());

  const Loading = connect(state => {
    return {
      loading: state.reducer.loading
    };
  })(({loading, children}) => {
    if (loading) {
      return null;
    }

    return children;
  });

  const baseName = window.location.pathname.replace(/^(\/[^/]+).*$/, '$1');

  const Routes = connect(state => {
    return {
      donationPageType: state.reducer.formContext.donationPageType
    };
  })(({donationPageType}) => {
    switch (donationPageType) {
      case '12_donationAnon':
        return <DonationAnonRoutes />;
      case '15_lead':
        return <LeadRoutes />;
      case '16_membership':
        return <MembershipRoutes />;
      case '17_taxDeductible':
        return <TaxDeductibleRoutes />;
      case '19_petition':
        return <PetitionRoutes />;
      case '10_donation':
      case '20_product':
      default:
        return <DonationRoutes />;
    }
  });

  ReactDOM.render(
    <Provider store={store}>
      <Loading>
        <Theme store={store}>
          <GlobalStyle />
          <BrowserRouter basename={baseName}>
            <SendTestInformation />
            <Routes />
          </BrowserRouter>
        </Theme>
      </Loading>
    </Provider>,
    document.getElementById('root'));

  serviceWorker.unregister();
};

if (window.isVwoEnabled) {
  let vwo = window.VWO || [];

  const startupId = setTimeout(() => {
    startup();
  }, 1000);

  vwo.push(['onVariationApplied', (...args) => {
    clearTimeout(startupId);
    startup();
  }]);
} else {
  startup();
}
