import React from 'react';
import {connect} from 'react-redux';


const SelectedAmountText = ({formContext, frequency, amount_m, showDonationFields}) => {
  const frequencyDefinition = formContext.frequencies.find(f => f.value === frequency);
  const theSelectedAmount = frequencyDefinition?.amounts?.find(a => a.amount_m === amount_m);

  if (showDonationFields && theSelectedAmount?.message) {
    return <div>{theSelectedAmount.message}</div>;
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    showDonationFields: state.form.donate_data_showDonationFields,
    amount_m: state.form.donate_data_amount_m,
    frequency: state.form.donate_data_frequency
  };
};

export default connect(mapStateToProps)(SelectedAmountText);
