import React from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import ProgressBar from '../../components/ProgressBar';
import Input from '../../components/Input';
import Label from '../../components/Label';
import {Field, Form, FormValidation, resetSubmitting} from '@nexios/frontend-forms';
import ErrorMessage from '../../components/ErrorMessage';
import FieldWrapper from '../../components/FieldWrapper';
import _ from 'lodash';
import {cleanseAddress, closeDialog, renderDialog, saveMembership} from '../../reducer';
import {gaMembership1} from '../../googleAnalytics/membership1';
import {connect} from 'react-redux';
import Validation from '../../components/Validation';
import DateInput from '../../components/DateInput';
import Checkbox from '../../components/Checkbox';
import RadioButtonContainer from '../../components/RadioButtonContainer';
import {Helmet} from 'react-helmet';
import Telemarketing from '../../components/Telemarketing';
import replaceNewLinesForHtml from '../../utils/replaceNewLines';
import parse from 'html-react-parser';
import styled, {withTheme} from 'styled-components';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';
import formatPhone from '@nexios/phone-number-formatter';
import genders from '../../utils/genders';
import parseAuthorizeLabel from '../../utils/parsePrivacyStatement';
import ModalDialog from '../../components/ModalDialog';
import IBAN from 'iban';
import {isSepaCountryIban} from '@nexios/validation';
import parseText from '../../utils/parseText';

const hasValue = val => !_.isNil(val) && _.trim(val) !== '';

const isAddressVisible = ctx => {
  return ctx.person.isAddressVisible;
};

const AddressRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
      flex: 3;
    }
  }
`;

const Membership1 = ({
                       formContext,
                       save,
                       history,
                       cleanse,
                       gaMembership1,
                       resetSubmittingForm,
                       progressBar,
                       renderDialog,
                       closeDialog,
                       isDialogVisible,
                       theme
                     }) => {
  useScrollToTop();
  let cleansing = false;

  const formConfig = {
    onSubmit: () => {
      if (cleansing) {
        resetSubmittingForm();
      } else {
        gaMembership1(formContext);
        save(history);
      }
    },
    onInit: ctx => {
      ctx.setList(
        'days',
        _.range(1, 31).map(value => ({label: `${value}`, value: `${value}`}))
      );
      ctx.setList('genders', genders(formContext));

      if (_.get(formContext, 'newsletterDefault', theme.allowNewsletterDefault) === true) {
        ctx.setDefaultValues({
          allowNewsletter: true
        });
      }
      if (formContext.telemarketingOptIn !== 'none' && _.get(formContext, 'telemarketingDefault', true) === true) {
        ctx.setDefaultValues({
          allowTelemarketing: true
        });
      }
    },
    onDataChanged: (ctx, callback) => {
      const postalCode = _.get(ctx, ['data', 'postalCode']);
      const previousPostalCode = _.get(ctx, ['previousData', 'postalCode']);
      const houseNumber = _.get(ctx, ['data', 'houseNumber_i']);
      const previousHouseNumber = _.get(ctx, ['previousData', 'houseNumber_i']);
      const houseNumberAddition = _.get(ctx, ['data', 'houseNumberAddition']);
      const previousHouseNumberAddition = _.get(ctx, ['previousData', 'houseNumberAddition']);

      let isChanged = false;

      if (postalCode !== previousPostalCode || houseNumber !== previousHouseNumber || houseNumberAddition !== previousHouseNumberAddition) {
        isChanged = true;
      }

      if (hasValue(postalCode) && hasValue(houseNumber) && isChanged) {
        // if we are already cleansing do not start again, request may interfer
        if (cleansing) {
          return;
        }

        cleansing = true;

        cleanse(
          {
            postalCode,
            houseNumber_i: houseNumber,
            houseNumberAddition
          },
          data => {
            if (data.status === 500) {
              data = {
                result: {
                  success: true
                },
                address: {
                  street: '',
                  city: ''
                }
              };
            }

            const addressCleansingStatus = _.get(data, ['result', 'success'], false) ? 'GREEN' : 'RED';

            const cleansedAddress = _.get(data, ['result', 'address']);

            if (addressCleansingStatus === 'GREEN') {
              ctx.setValues({
                street: cleansedAddress.street,
                city: cleansedAddress.city,
                postalCode: cleansedAddress.postalCode,
                houseNumber_i: cleansedAddress.houseNumber_i,
                houseNumberAddition: cleansedAddress.houseNumberAddition,
                addressCleansingStatus
              });
            } else {
              ctx.setValues({
                street: null,
                city: null,
                addressCleansingStatus
              });
            }

            ctx.validate(true);

            cleansing = false;

            return callback();
          }
        );
      } else if (!hasValue(postalCode) || !hasValue(houseNumber)) {
        ctx.setValues({
          addressCleansingStatus: null,
          street: null,
          city: null
        });
      }

      const email = _.get(ctx, ['data', 'email']);
      const previousEmail = _.get(ctx, ['previousData', 'email']);

      if (hasValue(email) && email !== previousEmail) {
        ctx.setValues({
          email: _.trim(email)
        });
      }

      return callback();
    },
    fields: {
      telemarketingInfoText: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'text' && ctx.telemarketingOptIn !== 'button') {
            return false;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      allowTelemarketing: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'checkbox') {
            return false;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      email: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.email) && ctx.email !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.email) || ctx.email === '') {
                return true;
              }

              return /^[-0-9a-zA-Z.+_]{1,63}@([-0-9a-zA-Z+_]+\.){1,63}[a-zA-Z]{1,63}$/.test(_.trim(ctx.email));
            },
            message: 'Er is geen geldig e-mailadres ingevuld.'
          }
        ]
      },
      gender: {
        isVisible: ctx => ctx.person.isGenderVisible === true,
        isRequired: ctx => ctx.person.isGenderRequired === true,
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isGenderRequired) {
                return !_.isNil(ctx.gender) && _.trim(ctx.gender) !== '';
              }

              return true;
            }
          }
        ]
      },
      initials: {
        isVisible: ctx => {
          return ctx.person.isInitialsVisible;
        },
        isRequired: ctx => {
          return ctx.person.isInitialsRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isInitialsRequired) {
                return !_.isNil(ctx.initials) && _.trim(ctx.initials) !== '';
              }

              return true;
            }
          }
        ]
      },
      firstName: {
        isVisible: ctx => {
          return ctx.person.isFirstNameVisible;
        },
        isRequired: ctx => {
          return ctx.person.isFirstNameRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isFirstNameRequired) {
                return !_.isNil(ctx.firstName) && _.trim(ctx.firstName) !== '';
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.firstName) || ctx.firstName === '') {
                return true;
              }

              return _.trim(ctx.firstName).length > 1;
            },
            message: 'Voornaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      infix: {},
      lastName: {
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.lastName) && ctx.lastName !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.lastName) || ctx.lastName === '') {
                return true;
              }

              return _.trim(ctx.lastName).length > 1;
            },
            message: 'Achternaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      postalCode: {
        isVisible: isAddressVisible,
        isRequired: ctx => {
          if (hasValue(ctx.houseNumber_i) || hasValue(ctx.houseNumberAddition)) {
            return true;
          }

          return ctx.person.isAddressRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (!hasValue(ctx.postalCode)) {
                return true;
              }

              return /^[1-9][0-9]{3}\W?[a-zA-Z]{2}$/.test(_.trim(ctx.postalCode));
            },
            message: 'Er is geen geldige postcode ingevuld.'
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.houseNumber_i) || hasValue(ctx.houseNumberAddition)) {
                return hasValue(ctx.postalCode);
              }

              if (ctx.person.isAddressRequired) {
                return hasValue(ctx.postalCode);
              }

              return true;
            }
          },
          {
            message: 'Het opgegeven adres kan niet worden gevonden.',
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          }
        ]
      },
      houseNumber_i: {
        isVisible: isAddressVisible,
        isRequired: ctx => {
          if (hasValue(ctx.postalCode) || hasValue(ctx.houseNumberAddition)) {
            return true;
          }

          return ctx.person.isAddressRequired;
        },
        rules: [
          {
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.houseNumber_i)) {
                return true;
              }

              return ctx.houseNumber_i.length < 8 && /^\d*$/.test(ctx.houseNumber_i);
            },
            message: 'Er is geen geldig huisnummer ingevuld. Controleer of er alleen cijfers gebruikt zijn.'
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.postalCode) || hasValue(ctx.houseNumberAddition)) {
                return hasValue(ctx.houseNumber_i);
              }

              if (ctx.person.isAddressRequired) {
                return hasValue(ctx.houseNumber_i);
              }

              return !(ctx.addressCleansingStatus === 'RED' && !_.isNil(ctx.houseNumber_i));
            }
          }
        ]
      },
      houseNumberAddition: {
        isVisible: isAddressVisible
      },
      telephone: {
        isVisible: ctx => {
          return ctx.person.isTelephoneVisible;
        },
        isRequired: ctx => {
          return ctx.person.isTelephoneRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isTelephoneRequired) {
                return hasValue(ctx.telephone);
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.telephone)) {
                return true;
              }

              return formatPhone(ctx.telephone).result === 'GREEN';
            },
            message: 'Er is geen geldig telefoonnummer ingevuld.'
          }
        ]
      },
      dateOfBirth: {
        isVisible: ctx => ctx.person.isDateOfBirthVisible,
        isRequired: ctx => {
          return ctx.person.isDateOfBirthRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isDateOfBirthRequired) {
                return hasValue(ctx.dateOfBirth);
              }

              return true;
            }
          }
        ]
      },
      street: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      city: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      allowNewsletter: {
        isVisible: () => formContext.allowNewsletter === true
      },
      iban: {
        isVisible: true,
        // isRequired: ctx => ctx.paymentMethod === 'directDebit',
        isRequired: true,
        rules: [
          {
            isValid: ctx => {
              return !_.isNil(ctx.iban) && ctx.iban !== '';
            }
          },
          {
            message: 'Vul hier een geldig IBAN nummer in.',
            isValid: ctx => {
              const value = ctx.iban;

              if (_.isNil(value) || value === '') {
                return true;
              }

              return IBAN.isValid(value);
            }
          },
          {
            message: 'IBAN moet afkomstig zijn uit een SEPA land',
            isValid: ctx => {
              const value = ctx.iban;
              if (_.isNil(value) || value === '') return true;

              return isSepaCountryIban(value);
            }
          }
        ]
      }
    }
  };

  const Email = () => (
    <div className='marginBottom'>
      <Field
        label='E-mailadres'
        name='email'
        inputComponent={Input}
        component={FieldWrapper}
        placeholder='E-mailadres'
        inputType='email'
        autoComplete='email'
      />
      <Field
        name='allowNewsletter'
        label={parseText(formContext, formContext.newsletterTextMembership || 'Houd mij op de hoogte over nieuwe ontwikkelingen.')}
        component={FieldWrapper}
        inputComponent={Checkbox}
        hideLabel
        subtle
      />
    </div>
  );

  const PersonForm = props => (
    <>
      <Gender />
      <Name isFirstNameVisible={props.isFirstNameVisible} />
      <Address {...props} />
      <Phone {...props} />
      <DateOfBirth />
      <Email />
      <Iban mandateText={parse(replaceNewLinesForHtml(parseText(formContext, formContext.membershipMandateText)))} />
    </>
  );

  let content = (
    <Box>
      <Title title={formContext.step1Header}>{theme.hideProgressBarMembership !== true && <ProgressBar progress={progressBar} />}</Title>
      <Form autoComplete='off' formInstanceKey='donate' formContext={formContext} formConfig={formConfig}>
        <FormValidation component={ErrorMessage} errorMessage='Je hebt nog niet alle velden ingevuld.' />
        <div className='widgetContent'>
          <div>{parse(replaceNewLinesForHtml(parseText(formContext, formContext.step1BodyText)))}</div>
          <Field
            name='renderAsPerson'
            telemarketingOptIn={formContext.telemarketingOptIn}
            telemarketingInfoText={parseText(formContext, formContext.telemarketingInfoText)}
            telemarketingCheckBoxText={parseText(formContext, formContext.telemarketingCheckBoxText)}
            isAddressVisible={formContext.person.isAddressVisible}
            isAddressOptional={!formContext.person.isAddressRequired}
            isFirstNameVisible={formContext.person.isFirstNameVisible}
            component={PersonForm}
          />
          <div className='marginBottom'>
            <FormValidation
              type='submit'
              label={parseText(formContext, formContext.membershipCallToActionLabel || 'Ja, ik word lid')}
              component={Button}
            />
          </div>
          {formContext.telemarketingOptIn === 'button' && (
            <Telemarketing
              telemarketingOptIn={formContext.telemarketingOptIn}
              telemarketingInfoText={parseText(formContext, formContext.telemarketingInfoText)}
              telemarketingCheckBoxText={parseText(formContext, formContext.telemarketingCheckBoxText)}
            />
          )}
          {formContext.privacyConfirmationTextMembership && (
            <div className='marginBottom'>
              <span>{parseAuthorizeLabel(formContext.privacyConfirmationTextMembership, renderDialog)}</span>
            </div>
          )}
          {formContext.entryCallToAction && (
            <div className='marginBottom centered'>
              {formContext.entryCallToActionUrl ? (
                <a className='link' href={formContext.entryCallToActionUrl} rel='noopener noreferrer'>
                  {formContext.entryCallToAction}
                </a>
              ) : (
                formContext.entryCallToAction
              )}
            </div>
          )}
        </div>
      </Form>
    </Box>
  );

  if (isDialogVisible) {
    content = (
      <ModalDialog
        close={() => {
          closeDialog();
        }}
      >
        <div dangerouslySetInnerHTML={{__html: formContext.privacyStatement}} />
      </ModalDialog>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formContext.contactDataBrowserTitle}</title>
      </Helmet>
      <MainContainer
        backgroundImageUrl={formContext.backgroundImageUrl}
        backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
        backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
      />
      <LayoutContainer
        position={formContext.textPosition}
        header={
          <div>
            <div style={{float: 'left', display: 'inline-block'}}>
              <Logo url={formContext.websiteUrl} />
            </div>
          </div>
        }
        content={content}
        sidebar={
          <>
            <Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />
          </>
        }
        faq={formContext.frequentlyAskedQuestions}
        footer={formContext.footer}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    formContext: state.reducer.formContext,
    isDialogVisible: state.reducer.isDialogVisible
  };
};

const mapDispatchToProps = dispatch => ({
  save: history => {
    dispatch(saveMembership(history));
  },
  gaMembership1: formContext => {
    dispatch(gaMembership1(formContext));
  },
  cleanse: (data, callback) => {
    dispatch(cleanseAddress(data, callback));
  },
  resetSubmittingForm: () => dispatch(resetSubmitting('donate')),
  renderDialog: e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(renderDialog());
  },
  closeDialog: () => {
    dispatch(closeDialog());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(Membership1)));

const Address = props => {
  if (!props.isAddressVisible) return <></>;
  return (
    <div className='marginBottom'>
      <Label label='Adres' isOptional={props.isAddressOptional} />
      <AddressRow>
        <Field
          name='postalCode'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Postcode'}
          inline
          hideValidation
          autoComplete='postal-code'
        />
        <Field
          name='houseNumber_i'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Huisn'}
          inline
          hideValidation
          inputType='tel'
          autoComplete='05d2ae54-5b99-4418-9d38-fde0ab6d553f'
        />
        <Field
          name='houseNumberAddition'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Toev'}
          hideValidation
          autoComplete='f1a99033-6af3-4aa2-94db-1de7ff1af61d'
        />
      </AddressRow>
      <Field name='postalCode' component={Validation} />
      <Field name='houseNumber_i' component={Validation} />
      <Field name='houseNumberAddition' component={Validation} />
      <Field component={({value}) => <div className='addressBox'>{value}&nbsp;</div>} name='street' />
      <Field component={({value}) => <div className='addressBox'>{value}</div>} name='city' />
    </div>
  );
};

const Phone = props => {
  return (
    <>
      <div className='marginBottom'>
        <Field
          label='Telefoonnummer'
          name='telephone'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder='Telefoonnummer'
          inputType='tel'
          autoComplete='tel'
        />
        {(props.telemarketingOptIn === 'text' || props.telemarketingOptIn === 'checkbox') && <Telemarketing {...props} />}
      </div>
    </>
  );
};

const DateOfBirth = () => (
  <div className='marginBottom'>
    <Field
      label='Geboortedatum'
      name='dateOfBirth'
      component={FieldWrapper}
      inputComponent={DateInput}
      autoComplete='5d65176f-1708-4b09-93c4-9b9e4f97e4dc'
    />
  </div>
);

const Gender = () => (
  <div className='marginBottom'>
    <Field
      label='Geslacht'
      name='gender'
      list='genders'
      component={FieldWrapper}
      inputComponent={props => {
        return <RadioButtonContainer fieldName='gender' valueName='gender' list={props.list} {...props} />;
      }}
    />
  </div>
);

const NameRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      flex: 3;
    }
  }
`;

const Name = props => (
  <div className='marginBottom'>
    <Label label='Naam' />
    {props.isFirstNameVisible && (
      <div className='marginBottomTen'>
        <Field
          name='firstName'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Voornaam'}
          hideValidation
          autoComplete='given-name'
        />
      </div>
    )}
    <NameRow>
      <Field
        name='initials'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Voorletters'}
        hideValidation
        inline
        autoComplete='00db6d76-1330-4b71-89aa-72fe7b9f238a'
      />
      <Field
        name='infix'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Tussenv'}
        hideValidation
        autoComplete='90ab3315-3929-4643-b78d-9de3e727eadf'
      />
      <Field
        name='lastName'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Achternaam'}
        hideValidation
        autoComplete='family-name'
      />
    </NameRow>
    <Field name='firstName' component={Validation} />
    <Field name='initials' component={Validation} />
    <Field name='infix' component={Validation} />
    <Field name='lastName' component={Validation} />
  </div>
);

const Iban = props => (
  <div className='marginBottom'>
    <Field label='Rekeningnummer' name='iban' component={FieldWrapper} inputComponent={Input} placeholder='NL11ABNA012345678' />
    <div className='marginBottom'>{props.mandateText}</div>
  </div>
);
