import React from 'react';
import FieldWrapper from '../FieldWrapper';
import Checkbox from '../Checkbox';
import {Field} from '@nexios/frontend-forms';
import styled from 'styled-components';


const SubtleText = styled.div`
  color: ${props => props.theme?.telemarketingText?.color || props.theme?.inputCheckbox?.label?.color || 'inherit'};
  font-size: ${props => props.theme?.telemarketingText?.fontSize || props.theme?.inputCheckbox?.label?.fontSize || 'inherit'};
  line-height: ${props => props.theme?.telemarketingText?.lineHeight || 'inherit'};
`;

const Container = styled.div`
  margin-top: ${props => props.theme?.telemarketingContainer?.marginTop || 'inherit'};
`;

function Telemarketing(
  {
    telemarketingOptIn,
    telemarketingInfoText,
    telemarketingCheckBoxText,
    noMargin
  }) {
  const infoTxt = telemarketingOptIn === 'text' || telemarketingOptIn === 'button' ?
    <SubtleText>{telemarketingInfoText}</SubtleText> : null;


  const checkboxField = <Field
    label={telemarketingCheckBoxText}
    name='allowTelemarketing'
    component={FieldWrapper}
    inputComponent={Checkbox}
    hideLabel
    subtle
    style={noMargin ? {marginTop: '5px'} : null}
    noMargin={noMargin}
  />;
  const text = <Field
    name='telemarketingInfoText'
    component={() => infoTxt}
  />;

  if (noMargin) {
    return <>
      {checkboxField}
      {text}
    </>;
  }
  return <Container className='marginBottom'>
    {checkboxField}
    {text}
  </Container>;
}

export default Telemarketing;
