import {getFormData} from '../helpers';
import _ from 'lodash';

export function gaMembership1(formContext) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);
    const previousPageVariantName = _.get(data, ['previousPageVariantName']);
    const isRedirect = !_.isNil(previousPageVariantName);
    const gaEvent = {
      'event': 'formSubmit',
      'formName': _.get(formContext, ['pageVariantName']),
      'newsletterSubscription': !!_.get(data, ['allowNewsletter'])
    };

    if (isRedirect) {
      gaEvent.membershipFormRedirect = previousPageVariantName;
    }

    window.dataLayer.push(gaEvent);
  };
}
