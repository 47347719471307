import SocialShareButton from '../SocialShareButton';
import React from 'react';

const SocialShare = ({formContext}) => {
  if (formContext.allowSocialShare) {
    const url = encodeURIComponent(document.querySelector('meta[property=\'og:url\']').getAttribute('content'));

    const socialMediaTitle = encodeURIComponent(formContext.socialMediaTitle);
    const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}&t=${socialMediaTitle}`;
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    const whatsappUrl = `https://wa.me/?text=${socialMediaTitle}%20${url}`;
    const twitterUrl = formContext.twitterTitle
      ? `https://twitter.com/share?text=${encodeURIComponent(formContext.twitterTitle)}&url=${url}`
      : `https://twitter.com/share?text=${socialMediaTitle}&url=${url}`;
    
    return (
      <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
        <SocialShareButton name='whatsapp' url={whatsappUrl} />
        <SocialShareButton name='facebook' url={facebookUrl} />
        <SocialShareButton name='linkedin' url={linkedInUrl} />
        <SocialShareButton name='twitter' url={twitterUrl} />
      </div>
    );
  }
  return null;
};

export default SocialShare;
