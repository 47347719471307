import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Membership1 from './views/Membership1';
import Membership2 from './views/Membership2';
import MembershipAlreadyCompleted from './views/MembershipAlreadyCompleted';
import Wizard from './Wizard';

export default function MembershipRoutes () {
  return (
    <Switch>
      <Route
        path='/'
        exact>
        <Wizard render={({isDonationCompleted}) => (
          isDonationCompleted ? <MembershipAlreadyCompleted /> : <Membership1 progressBar='1of2' />
        )} />
      </Route>
      <Route
        path='/bedankt'
        exact>
        <Membership2 progressBar='2of2' />
      </Route>
      <Redirect
        to='/'
      />
    </Switch>
  );
}
