import styled from 'styled-components';

const AddressRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
      flex: 3;
    }
  }
`;

export default AddressRow;
