import React from 'react';
import {connect} from 'react-redux';
import parseText from '../../utils/parseText';

const getAmountConfirmationText = (ctx) => {
  switch (ctx.frequency) {
    case 'oneOff':
      return parseText(ctx, `Ik geef eenmalig €{{amount}} ${ctx.optInAdminCostContribution === true ? '+ {{adminCostContribution}}' : ''}`);
    case 'temporary':
      return parseText(ctx, 'Ik geef {{period}} lang, elke {{frequency}} €{{amount}}');
    default:
      return parseText(ctx, 'Ik geef €{{amount}} per {{frequency}}');
  }
};

const DonationConfirmationAmountText = (props) => {
  if ((props.amount_m || props.customAmount_m) && props.showDonationFields) return <div>{getAmountConfirmationText(props)}</div>;
  return null;
};

const mapStateToProps = (state) => {
  return {
    showDonationFields: state.form.donate_data_showDonationFields,
    amount_m: state.form.donate_data_amount_m,
    customAmount_m: state.form.donate_data_customAmount_m,
    frequency: state.form.donate_data_frequency,
    adminCostContributionAmount_m: state.form.donate_data_adminCostContributionAmount_m,
    optInAdminCostContribution: state.form.donate_data_optInAdminCostContribution
  };
};

export default connect(mapStateToProps)(DonationConfirmationAmountText);
