import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  color: ${props => props.theme.validation.color};
  font-size: ${props => props.theme.validation.fontSize};
  margin-top: ${props => props.noMargin ? 'inherit' : '5px'};
`;

Container.defaultProps = {
  theme: {
    validation: {
      fontSize: '16px',
      color: '#e8141e'
    }
  }
};

export default class Validation extends Component {
  static propTypes = {
    infos: PropTypes.array,
    warnings: PropTypes.array,
    errors: PropTypes.array,
    noMargin: PropTypes.bool
  };

  render() {
    const {errors, warnings, infos} = this.props;

    if (this.props.noMargin === true) {
      return <>
        {errors && errors.map(e => <Container noMargin={this.props.noMargin} key={e}>{e}</Container>)}
        {warnings && warnings.map(e => <Container noMargin={this.props.noMargin} key={e}>{e}</Container>)}
        {infos && infos.map(e => <Container noMargin={this.props.noMargin} key={e}>{e}</Container>)}
      </>;
    }
    return <Container>
      {errors && errors.map(e => <div key={e}>{e}</div>)}
      {warnings && warnings.map(e => <div key={e}>{e}</div>)}
      {infos && infos.map(e => <div key={e}>{e}</div>)}
    </Container>
  }
}
