import React, {useState} from 'react';
import styled, {css} from 'styled-components';

const isChecked = css`
  background-color: ${props => props.disabled ? props.theme.inputRadioButton.backgroundColorDisabled : props.theme.inputRadioButton.backgroundColorActive};
  border: ${props => props.disabled ? props.theme.inputRadioButton.borderDisabled : props.theme.inputRadioButton.borderActive};

  :after {
    content: '';
    position: absolute;
    display: block;
    top: ${props => props.theme.inputRadioButton.fillSize === 'large' ? '3px' : '5px'};
    left: ${props => props.theme.inputRadioButton.fillSize === 'large' ? '3px' : '5px'};
    width: ${props => props.theme.inputRadioButton.fillSize === 'large' ? '14px' : '10px'};
    height: ${props => props.theme.inputRadioButton.fillSize === 'large' ? '14px' : '10px'};
    border-radius: 50%;
    background: ${props => props.theme.inputRadioButton.fillColor || '#fff'};
  }
`;

const InputRadioButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: ${props => props.disabled ? props.theme.inputRadioButton.backgroundColorDisabled : props.theme.inputRadioButton.backgroundColor};
  border: ${props => props.disabled ? props.theme.inputRadioButton.borderDisabled : props.theme.inputRadioButton.border};
  border-radius: 50%;
  border-width: ${props => props.hasFocus ? '2px' : '1px'};
  ${props => props.isChecked ? isChecked : null}
`;

const hasError = css`
  color: ${props => props.theme.radio.colorHasError};

  ${InputRadioButton} {
    border: ${props => props.theme.radio.borderHasError};
  }
`;

const Radio = styled.label`
  width: auto;
  display: inline-block;
  position: relative;
  padding: 0 10px 0 ${props => props.theme.radio.rightPadding || '30px'};

  font-weight: ${props => props.isChecked ? props.theme.inputRadioButton.fontWeightActive || 'normal' : 'normal'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: ${props => props.theme.inputRadioButton.fontSize || '16px'};
  user-select: none;
  color: ${props => props.disabled ? props.theme.inputRadioButton.borderDisabled : 'inherit'}

  ${props => props.hasErrors ? hasError : null}
  :hover {
    ${InputRadioButton} {
      border: ${props => props.disabled ? props.theme.inputRadioButton.borderDisabled : props.theme.radio.borderHover}
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

InputRadioButton.defaultProps = {
  theme: {
    inputRadioButton: {
      backgroundColor: '#fff',
      border: '1px solid #c4c4c4',
      backgroundColorActive: '#199a57',
      borderActive: '1px solid #199a57',
      backgroundColorFocus: '#818181',
      borderFocus: '2px solid #818181'
    }
  }
};

Radio.defaultProps = {
  theme: {
    radio: {
      borderHover: '1px solid #818181',
      colorHasError: '#e8141e',
      borderHasError: '1px solid #e8141e'
    }
  }
};

export default function RadioButton(props) {
  const [hasFocus, setHasFocus] = useState(false);
  const onChange = e => {
    props.setValue(e.target.value);

    if (props.validate) {
      props.validate();
    }
  };

  return (
    <Radio hasErrors={props.hasErrors} disabled={props.disabled} isChecked={props.isChecked}>
      {props.label}
      <input
        disabled={props.disabled}
        name={props.name}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        type='radio'
        value={props.value}
        onChange={onChange}
        checked={props.isChecked}
      />
      <InputRadioButton isChecked={props.isChecked} hasFocus={hasFocus} disabled={props.disabled} />
    </Radio>
  );
}
