import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';
import {Field, Form, FormValidation, resetSubmitting} from '@nexios/frontend-forms';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {cleanseAddress, closeDialog, renderDialog, savePetition} from '../../reducer';

// Utils
import parseAuthorizeLabel from '../../utils/parsePrivacyStatement';
import replaceNewLinesForHtml from '../../utils/replaceNewLines';

// Hooks
import useScrollToTop from '../../hooks/useScrollToTop';

// Components
import Box from '../../components/Box';
import Button from '../../components/Button';
import ButtonContainer from '../../components/ButtonContainer';
import Checkbox from '../../components/Checkbox';
import DateInput from '../../components/DateInput';
import Dropdown from '../../components/Dropdown';
import ErrorMessage from '../../components/ErrorMessage';
import FieldWrapper from '../../components/FieldWrapper';
import Input from '../../components/Input';
import InputAmount from '../../components/InputAmount';
import Label from '../../components/Label';
import LayoutContainer from '../../components/LayoutContainer';
import Logo from '../../components/Logo';
import MainContainer from '../../components/MainContainer';
import ModalDialog from '../../components/ModalDialog';
import PaymentMethodButtonContainer from '../../components/PaymentMethodButtonContainer';
import ProgressBar from '../../components/ProgressBar';
import RadioButtonContainer from '../../components/RadioButtonContainer';
import SinglePeriod from '../../components/SingePeriod';
import Teaser from '../../components/Teaser';
import Telemarketing from '../../components/Telemarketing';
import TextArea from '../../components/TextArea';
import Title from '../../components/Title';
import Validation from '../../components/Validation';

// Local imports
import AddressRow from './AddressRow';
import DirectDebitText from './DirectDebitText';
import NameRow from './NameRow';
import DonationConfirmationAmountText from './DonationConfirmationAmountText';
import SelectedAmountText from './SelectedAmountText';
import createFormConfig from './createFormConfig';
import RadioButton from '../../components/RadioButton';
import FormLayout from '../../components/FormLayout';

const Petition1 = ({formContext, save, history, cleanse, resetSubmittingForm, progressBar, renderDialog, closeDialog, isDialogVisible}) => {
  useScrollToTop();
  let cleansing = false;

  const formConfig = createFormConfig({formContext, cleansing, cleanse, resetSubmittingForm, save, history});

  let content = (
    <Box>
      <Title title={formContext.step1Header}><ProgressBar progress={progressBar} /></Title>
      <Form
        autoComplete='off'
        formInstanceKey='donate'
        formContext={formContext}
        formConfig={formConfig}>
        <FormValidation
          component={ErrorMessage}
          errorMessage='Je hebt nog niet alle velden ingevuld.' />

        <div className='widgetContent'>
          <FormLayout>
            {!_.isNil(formContext.step1BodyText) && <div>{parse(replaceNewLinesForHtml(formContext.step1BodyText))}</div>}
            <Field
              label={formContext.variableFieldLabel}
              name='variableFieldAnswer'
              inputComponent={TextArea}
              component={FieldWrapper}
              placeholder={formContext.variableFieldPlaceholder}
            />
            <Field
              noMargin
              label='Geslacht'
              name='gender'
              list='genders'
              component={FieldWrapper}
              inputComponent={(props) => {
                return <RadioButtonContainer fieldName='gender' valueName='gender' list={props.list} {...props} />;
              }}
            />
            <div id='name-container'>
              <Label label='Naam' />
              <Field
                style={{marginBottom: '10px'}}
                name='firstName'
                component={FieldWrapper}
                inputComponent={Input}
                placeholder={'Voornaam'}
                hideValidation
                autoComplete='given-name'
              />
              <NameRow>
                <Field
                  name='initials'
                  component={FieldWrapper}
                  inputComponent={Input}
                  placeholder={'Voorletters'}
                  hideValidation
                  inline
                  autoComplete='00db6d76-1330-4b71-89aa-72fe7b9f238a'
                />
                <Field
                  name='infix'
                  component={FieldWrapper}
                  inputComponent={Input}
                  placeholder={'Tussenv'}
                  hideValidation
                  autoComplete='90ab3315-3929-4643-b78d-9de3e727eadf'
                />
                <Field
                  name='lastName'
                  component={FieldWrapper}
                  inputComponent={Input}
                  placeholder={'Achternaam'}
                  hideValidation
                  autoComplete='family-name'
                />
              </NameRow>

              <Field
                name='firstName'
                noMargin
                component={Validation} />
              <Field
                name='initials'
                noMargin
                component={Validation} />
              <Field
                name='infix'
                noMargin
                component={Validation} />
              <Field
                name='lastName'
                noMargin
                component={Validation} />
            </div>
            {formContext.person.isAddressVisible && <div id='address-container'>
              <Label label='Adres' isOptional={!formContext.isAddressRequired} />
              <AddressRow>
                <Field
                  name='postalCode'
                  component={FieldWrapper}
                  inputComponent={Input}
                  placeholder={'Postcode'}
                  inline
                  hideValidation
                  autoComplete='postal-code'
                />
                <Field
                  name='houseNumber_i'
                  component={FieldWrapper}
                  inputComponent={Input}
                  placeholder={'Huisn'}
                  inline
                  hideValidation
                  inputType='tel'
                  autoComplete='05d2ae54-5b99-4418-9d38-fde0ab6d553f'
                />
                <Field
                  name='houseNumberAddition'
                  component={FieldWrapper}
                  inputComponent={Input}
                  placeholder={'Toev'}
                  hideValidation
                  autoComplete='f1a99033-6af3-4aa2-94db-1de7ff1af61d'
                />
              </AddressRow>
              <Field
                name='postalCode'
                noMargin
                component={Validation} />
              <Field
                name='houseNumber_i'
                noMargin
                component={Validation} />
              <Field
                name='houseNumberAddition'
                noMargin
                component={Validation} />
              <Field
                component={({value}) => <div className='addressBox'>{value}&nbsp;</div>}
                name='street' />
              <Field
                component={({value}) => <div className='addressBox'>{value}</div>}
                name='city' />
            </div>}
            <Field
              label='Telefoonnummer'
              name='telephone'
              component={props => <FieldWrapper {...props} noMargin>
                {(formContext.telemarketingOptIn === 'text' || formContext.telemarketingOptIn === 'checkbox') && <Telemarketing
                  noMargin
                  telemarketingOptIn={formContext.telemarketingOptIn}
                  telemarketingInfoText={formContext.telemarketingInfoText}
                  telemarketingCheckBoxText={formContext.telemarketingCheckBoxText}
                />}
              </FieldWrapper>}
              inputComponent={Input}
              placeholder='Telefoonnummer'
              inputType='tel'
              autoComplete='tel'
            />

            <Field
              noMargin
              label='Geboortedatum'
              name='dateOfBirth'
              component={FieldWrapper}
              inputComponent={DateInput}
              autoComplete='5d65176f-1708-4b09-93c4-9b9e4f97e4dc'
            />
            <Field
              noMargin
              label='E-mailadres'
              name='email'
              inputComponent={Input}
              component={props => <FieldWrapper {...props}>
                <Field
                  noMargin
                  style={{marginTop: '5px'}}
                  name='allowNewsletter'
                  label={formContext.newsletterText || 'Houd mij op de hoogte over nieuwe ontwikkelingen.'}
                  component={FieldWrapper}
                  inputComponent={Checkbox}
                  hideLabel
                />
              </FieldWrapper>}
              placeholder='E-mailadres'
              inputType='email'
              autoComplete='email'
            />

            <Field
              noMargin
              name='variableCheckboxAnswer'
              label={formContext.variableCheckboxLabel || '-'}
              component={FieldWrapper}
              inputComponent={Checkbox}
              hideLabel
            />

            <Field
              name='showDonationFields'
              component={FieldWrapper}
              inputComponent={(props) => {
                return <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                  <RadioButton
                    name={props.name}
                    key={`${props.name}-yes`}
                    hasErrors={props.hasErrors}
                    isChecked={props.value === true}
                    label='Ik wil ook een donatie doen'
                    validate={props.validate}
                    disabled={props.disabled}
                    setValue={() => props.setValue(true)} />
                  <RadioButton
                    name={props.name}
                    key={`${props.name}-no`}
                    hasErrors={props.hasErrors}
                    isChecked={props.value === false}
                    label='Nee, ik doe geen donatie'
                    validate={props.validate}
                    disabled={props.disabled}
                    setValue={() => props.setValue(false)} />
                </div>;
              }}
            />

            <Field
              list='frequencies'
              name='frequency'
              component={(props) => {
                const list = _.get(props, ['list']);
                // do not render options if there is only 1 frequency
                if (_.size(list) < 2 && formContext.frequencyDisplay === 'dropdown') {
                  return null;
                }
                return <>
                  {formContext.frequencyDisplay === 'dropdown' && <Dropdown {...props} />}
                  {formContext.frequencyDisplay === 'radiobutton' && <RadioButtonContainer {...props} />}
                </>;
              }}
            />

            <Field
              list='amounts'
              name='amount_m'
              component={(props) => {
                const list = props.list.map(a => {
                  a.ribbon = a.value.ribbon;
                  a.icon = a.value.icon;
                  a.value = a.value.value;
                  return a;
                });

                return <ButtonContainer
                  fieldName='amount_m'
                  valueName='amount_m'
                  list={list} />;
              }}
            />

            <Field
              name='setCustomAmountVisible'
              component={({updateField}) => <div className='centered'>
                <button type='button' className='link' onClick={() => {
                  updateField('isCustomAmountVisible', true);
                  updateField('amount_m', null);
                }}>
                  Of kies een ander bedrag
                </button>
              </div>
              }
            />

            <SelectedAmountText formContext={formContext} />

            <Field
              label={formContext.customAmountDisplay !== 'inline' ? 'Vul het bedrag in dat je wilt doneren' : null}
              name='customAmount_m'
              placeholder={formContext.customAmountDisplay === 'inline' ? 'Of vul hier een ander bedrag in' : '0,00'}
              component={FieldWrapper}
              inputComponent={InputAmount}
            />

            <Field
              name='setCustomAmountHidden'
              component={({updateField}) => <div className='centered'>
                <button type='button' className='link' onClick={() => {
                  updateField('isCustomAmountVisible', false);
                  updateField('customAmount_m', null);
                }}>Of kies bedrag
                </button>
              </div>}
            />

            <Field
              list='periods'
              component={(props) => {
                const list = _.get(props, ['list']);
                // do not render options if there is only 1 frequency
                if (_.size(list) === 0) {
                  return null;
                }
                if (_.size(list) === 1) {
                  return <SinglePeriod label={list[0].label} />;
                }
                return <>
                  {formContext.periodDisplay === 'dropdown' && <Dropdown {...props} placeholder='Selecteer een periode...' />}
                  {formContext.periodDisplay === 'radiobutton' && <RadioButtonContainer {...props} />}
                </>;
              }}
              name='period'
            />

            <DonationConfirmationAmountText />

            <Field
              name='paymentMethod'
              component={(props) => {
                return <PaymentMethodButtonContainer
                  fieldName='paymentMethod'
                  valueName='paymentMethod'
                  list={props.list} />;
              }}
              list='paymentMethods'
            />
            <Field
              list='idealBanks'
              name='bank'
              component={FieldWrapper}
              placeholder={'Kies je bank'}
              inputComponent={Dropdown}
            />
            <Field
              list='creditCardBanks'
              name='creditCardBank'
              component={FieldWrapper}
              placeholder={'Kies je creditcard'}
              inputComponent={Dropdown}
            />
            <Field
              label='Rekeningnummer'
              name='iban'
              component={FieldWrapper}
              inputComponent={Input}
              placeholder='NL11ABNA012345678' />

            <DirectDebitText formContext={formContext} />

            {formContext.authorizeAsCheckbox && <Field
              noMargin
              hideLabel
              name='authorize'
              component={FieldWrapper}
              inputComponent={Checkbox}
              label={<Field
                name='authorizeLabel'
                component={({value}) =>
                  <span>{parseAuthorizeLabel(value, renderDialog)}</span>} />} />}

            {!formContext.authorizeAsCheckbox && <Field
              name='authorizeLabel'
              component={({value}) =>
                <span>{parseAuthorizeLabel(value, renderDialog)}</span>} />}

            <FormValidation
              type='submit'
              label={formContext.petitionCallToActionLabel || 'Ondertekenen'}
              component={Button}
            />
            {formContext.privacyConfirmationTextPetition && <span>{parseAuthorizeLabel(formContext.privacyConfirmationTextPetition, renderDialog)}</span>}
            {formContext.telemarketingOptIn === 'button' && <Telemarketing
              noMargin
              telemarketingOptIn={formContext.telemarketingOptIn}
              telemarketingInfoText={formContext.telemarketingInfoText}
              telemarketingCheckBoxText={formContext.telemarketingCheckBoxText}
            />}
            {formContext.entryCallToAction &&
              <div className='centered'>
                {formContext.entryCallToActionUrl ? <a className='link' href={formContext.entryCallToActionUrl} rel='noopener noreferrer'>{formContext.entryCallToAction}</a> : formContext.entryCallToAction}
              </div>}
          </FormLayout>
        </div>
      </Form>
    </Box>
  );

  if (isDialogVisible) {
    content = <ModalDialog close={() => {
      closeDialog();
    }}>
      <div dangerouslySetInnerHTML={{__html: formContext.privacyStatement}} />
    </ModalDialog>;
  }

  return <>
    <Helmet>
      <title>{formContext.petitionBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={content}
      sidebar={<>
        <Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />
      </>
      }
      faq={formContext.frequentlyAskedQuestions}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    isDialogVisible: state.reducer.isDialogVisible
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (history, submittedFormData) => {
    dispatch(savePetition(history, submittedFormData));
  },
  cleanse: (data, callback) => {
    dispatch(cleanseAddress(data, callback));
  },
  resetSubmittingForm: () => dispatch(resetSubmitting('donate')),
  renderDialog: (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(renderDialog());
  },
  closeDialog: () => {
    dispatch(closeDialog());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Petition1));

