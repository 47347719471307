import {css} from 'styled-components';
import logoImg from './icons/eha/eha-logo.png';
import progress1 from './icons/eha/step-indicator-1.svg';
import progress2 from './icons/eha/step-indicator-2.svg';
import progress3 from './icons/eha/step-indicator-3.svg';

import progress1of2 from './icons/eha/step-indicator-1of2.svg';
import progress2of2 from './icons/eha/step-indicator-2of2.svg';

import whatsapp from './icons/sho/whatsapp.png';
import facebook from './icons/sho/facebook.svg';
import linkedin from './icons/sho/linkedin.svg';
import instagram from './icons/sho/instagram.svg';
import twitter from './icons/sho/x.svg';

// eslint-disable-next-line no-unused-vars

const PRIMARY = '#FF7417';
const SECONDARY = '#0065AD';
const GREEN = '#33B498';
// const YELLOW = '#FFF264';
// const UI900 = '#1D1E1B';
const UI700 = '#575451';
const UI500 = '#E7DED6';
// const UI300 = '#FDF4EF';
const WHITE = '#FFFFFF';
const PRIMARY_HOVER = '#FF7417AA';
const ERROR = '#c02b0a';

let defaultFont = '"Figtree", "Arial", "sans-serif"';
let defaultFontHeavy = '"Figtree", "Arial", "sans-serif"';

export default {
  // GlobalStyles
  bodyFontFamily: defaultFont,
  h1FontFamily: defaultFont,
  h3FontFamily: defaultFont,
  h1FontSize: '21px',
  bodyFontSize: '16px',
  // AmountButton
  amountButton: {
    color: PRIMARY,
    fontFamily: defaultFont,
    fontSize: '20px',
    width: '96px',
    height: '64px',
    border: `1px solid ${UI700}`,
    borderRadius: '12px',
    backgroundColor: '#fff',
    backgroundColorActive: 'rgba(255,116,23,0.1)',
    borderHover: `1px solid ${PRIMARY}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${PRIMARY}`
  },
  buttonContainerSpaceBetween: {
    width: '96px'
  },
  //BackButton
  backButton: {
    fontSize: '16px',
    color: '#1A1A1A'
  },
  // Box
  boxComponent: {
    borderRadius: '12px',
    border: '0px'
  },
  // Button
  button: {
    borderRadius: '8px',
    fontFamily: defaultFontHeavy,
    fontSize: '20px',
    height: '56px',
    backgroundColor: PRIMARY,
    color: '#fff',
    boxShadow: 'none',
    active: css`
      background-color: ${PRIMARY_HOVER};
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: ${PRIMARY_HOVER};
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // Checkbox
  inputCheckbox: {
    border: `1px solid ${UI500}`
  },
  box: {
    fontSize: '16px',
    colorHasError: ERROR,
    borderHasError: `1px solid ${ERROR}`,
    borderHover: '1px solid #818181',
    backgroundColorChecked: SECONDARY,
    backgroundBorderChecked: '1px solid #199A57'
  },
  // DateInput
  dateDropdown: {
    padding: '15.6px 30px 12.4px 10px',
    border: `1px solid ${GREEN}`,
    borderRadius: '5px',
    boxShadowFocus: '0',
    borderFocus: `1px solid ${UI500}`,
    borderValid: `1px solid ${GREEN}`,
    boxShadowError: '0',
    boxShadowValid: '0',
    backgroundColorValid: 'inherit',
    height: 'auto',
    chevronTop: '16px'
  },
  // DisplayIban
  iban: {
    fontFamily: defaultFont,
    color: '#fff'
  },
  // Dropdown
  dropdown: {
    height: '46px',
    borderRadius: '3px',
    backgroundColor: '#fff',
    fontSize: '18px',
    paddingLeft: '13px',
    border: `1px solid ${GREEN}`,
    borderFocus: `1px solid ${GREEN}`,
    boxShadowFocus: '0'
  },
  // ErrorMessage
  error: {
    backgroundColor: WHITE,
    color: ERROR,
    height: '49px'
  },
  // Iban
  ibanContainer: {
    display: 'none'
  },
  // Input
  inputComponent: {
    fontSize: '16px',
    border: `1px solid ${GREEN}`,
    borderFocus: `1px solid ${UI500}`,
    borderRadius: '3px',
    boxShadowFocus: 'none',
    colorPlaceholder: '#c4c4c4',
    borderValid: `1px solid ${GREEN}`,
    boxShadowValid: 'none',
    borderError: `1px solid ${ERROR}`,
    boxShadowError: 'none',
    colorError: ERROR,
    backgroundColorError: WHITE
  },
  // TextArea
  textAreaComponent: {
    fontSize: '16px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid #818181',
    boxShadowFocus: 'none',
    colorPlaceholder: '#c4c4c4',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #E8141E',
    boxShadowError: 'none',
    colorError: ERROR,
    backgroundColorError: WHITE
  },
  // Label
  optionalLabel: {
    color: '#818181'
  },
  label: {
    fontSize: '16px',
    color: '#000',
    colorError: ERROR
  },
  logo: {
    width: '340px',
    height: '90px',
    backgroundImage: logoImg,
    widthMobile: '120px',
    heightMobile: '103px',
    margin: '0',
    marginMobile: '0'
  },
  // PaymentButton
  paymentButton: {
    color: PRIMARY,
    fontFamily: defaultFont,
    fontSize: '16px',
    width: '115px',
    height: '78px',
    lineHeight: '78px',
    border: `1px solid ${UI700}`,
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: 'rgba(255,116,23,0.15)',
    borderHover: `2px solid ${PRIMARY}`
  },
  // RadioButton
  inputRadioButton: {
    backgroundColor: '#fff',
    border: '1px solid #c4c4c4',
    backgroundColorActive: PRIMARY,
    borderActive: `1px solid ${PRIMARY}`
  },
  // ProgressBar
  progressBar: {
    step1: css`
      width: 70px;
      background: url(${progress1}) no-repeat 0;
    `,
    step2: css`
      width: 70px;
      background: url(${progress2}) no-repeat 0;
    `,
    step3: css`
      width: 70px;
      background: url(${progress3}) no-repeat 0;
    `,
    step1of2: css`
      width: 40px;
      background: url(${progress1of2}) no-repeat 0;
    `,
    step2of2: css`
      width: 40px;
      background: url(${progress2of2}) no-repeat 0;
    `
  },
  radio: {
    borderHover: `1px solid ${PRIMARY_HOVER}`,
    colorHasError: ERROR,
    borderHasError: `1px solid ${ERROR}`
  },
  // TeaserHeader
  teaser: {
    display: 'inline',
    fontFamily: defaultFontHeavy,
    fontSize: '21px',
    lineHeight: '47px',
    backgroundColor: SECONDARY,
    backgroundColorSubtitle: WHITE,
    subtitleMarkerColor: WHITE,
    color: '#fff',
    colorSubtitle: '#000',
    padding: '4px 8px',
    subtitleFontFamily: defaultFont,
    subtitleFontSize: '16px',
    subtitleLineHeight: '24px',
    topLeftTextAlignment: 'left',
    topRightTextAlignment: 'left',
    bottomLeftTextAlignment: 'left',
    bottomRightTextAlignment: 'left'
  },
  // Title
  title: {
    color: '#fff',
    fontFamily: defaultFontHeavy,
    fontSize: '21px',
    height: '65px',
    fontWeight: 'normal',
    borderRadius: '8px',
    backgroundColor: SECONDARY
  },
  // Validation
  validation: {
    color: ERROR,
    fontSize: '15px'
  },
  // OptIn
  optInButton: {
    fontFamily: defaultFont,
    fontSize: '20px',
    backgroundColor: '#fff',
    color: SECONDARY,
    boxShadow: 'none',
    active: css`
      background-color: #fff;
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: #fff;
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // SocialShareButton
  socialShareButton: {
    backgroundSize: '50px',
    color: '#000',
    whatsapp: css`background-image: url(${whatsapp})`,
    facebook: css`background-image: url(${facebook})`,
    twitter: css`background-image: url(${twitter})`,
    linkedin: css`background-image: url(${linkedin})`,
    instagram: css`background-image: url(${instagram})`
  }
};


