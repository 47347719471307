import _ from 'lodash';
import {formReducer, reset} from '@nexios/frontend-forms';
import {combineReducers} from 'redux';

export const INIT = 'api.init';
export const UPDATE_SESSION = 'api.updateSession';
export const DESTROY_SESSION = 'api.destroySession';
export const CLEANSE_ADDRESS = 'api.cleanse.address';
export const SET_IS_CLEANSING = 'setIsCleansing';
export const CLEANSE_NAME = 'api.cleanse.name';
export const RENDER_DIALOG = 'render_dialog';
export const CLOSE_DIALOG = 'close_dialog';
export const SAVE = 'api.save';
export const SAVE_TAXDEDUCTIBLE = 'api.save.taxDeductible';
export const SUBSCRIBE_TO_OPT_IN = 'api.subscribeToOptIn';
export const PAYING = 'isPaying';
export const STORE_REFERENCE_KEY = 'store_reference_key';
export const SAVE_LEAD = 'api.save.lead';
export const SAVE_PETITION = 'api.save.petition';
export const SAVE_MEMBERSHIP = 'api.save.membership';
export const REDIRECT = 'redirect';

export function init() {
  return {
    type: INIT
  };
}

export function updateSession(fields, donationState, callback) {
  return {
    type: UPDATE_SESSION,
    fields,
    donationState,
    callback
  };
}

export function destroySession(history) {
  return (dispatch) => {
    dispatch(reset('donate'));
    dispatch({
      type: DESTROY_SESSION,
      history
    });
  };
}

export function setIsCleansing(value) {
  return {
    type: SET_IS_CLEANSING,
    value
  };
}

export function cleanseAddress(data, callback) {
  return {
    type: CLEANSE_ADDRESS,
    data,
    callback
  };
}

export function cleanseName (data, callback) {
  return {
    type: CLEANSE_NAME,
    data,
    callback
  };
}

export function renderDialog() {
  return (dispatch) => {
    return dispatch({
      type: RENDER_DIALOG
    });
  };
}

export function closeDialog() {
  return (dispatch) => {
    return dispatch({
      type: CLOSE_DIALOG
    });
  };
}

export function save(history) {
  return (dispatch) => {
    return dispatch({
      type: SAVE,
      history
    });
  };
}

export function saveTaxDeductible(history) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_TAXDEDUCTIBLE,
      history
    });
  };
}

export function subscribeToOptIn(campaignPropositionCode, pageVariantKey, email, telephone) {
  return (dispatch) => {
    return dispatch({
      type: SUBSCRIBE_TO_OPT_IN,
      campaignPropositionCode,
      pageVariantKey,
      email,
      telephone
    });
  };
}

export function saveLead(history) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_LEAD,
      history
    });
  };
}

export function savePetition(history, submittedFormData) {
  return (dispatch) => {
    if (submittedFormData?.showDonationFields) {
      return dispatch({
        type: SAVE,
        history,
        submittedFormData
      });
    }

    return dispatch({
      type: SAVE_PETITION,
      history,
      submittedFormData
    });
  };
}

export function saveMembership (history) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_MEMBERSHIP,
      history
    });
  };
}

export function doRedirect (config) {
  return {
    type: REDIRECT,
    config
  };
}

export function completeDonation() {
  return (dispatch) => {
    dispatch(updateSession(null, {
      isDonationCompleted: true
    }));
  };
}

const initialState = {
  loading: true,
  isDonationCompleted: false,
  isStep1Completed: false,
  isStep2Completed: false,
  isStep3Completed: false,
  isPaying: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...action.donationState,
        loading: false,
        formContext: action.config,
        theme: _.get(action, ['config', 'theme']),
        isProductForm: _.get(action, ['config', 'donationPageType']) === '20_product'
      };
    }
    case UPDATE_SESSION: {
      return _.assign({}, state, action.donationState);
    }
    case DESTROY_SESSION: {
      return {
        ...state,
        loading: false,
        isDonationCompleted: false,
        isStep1Completed: false,
        isStep2Completed: false,
        isStep3Completed: false,
        isPaying: false,
        isProductForm: false
      };
    }
    case SET_IS_CLEANSING:
      return {
        ...state,
        isCleansing: action.value
      };
    case CLEANSE_ADDRESS:
      return state;
    case CLEANSE_NAME:
      return state;
    case RENDER_DIALOG:
      return {
        ...state,
        isDialogVisible: true
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        isDialogVisible: false
      };
    case PAYING: {
      return {
        ...state,
        isPaying: action.isPaying === undefined ? true : action.isPaying
      };
    }
    case STORE_REFERENCE_KEY:
      return {
        ...state,
        referenceKey: action.referenceKey
      };
    default:
      return state;
  }
}

export default combineReducers({
  reducer,
  form: formReducer
});
