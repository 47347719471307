import _ from 'lodash';
import genders from '../../utils/genders';
import formatPhone from '@nexios/phone-number-formatter';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';
import {isSepaCountryIban} from '@nexios/validation';
import BigNumber from 'bignumber.js';
import IBAN from 'iban';

BigNumber.config({
  FORMAT: {
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3
  }
});
const hasValue = val => !_.isNil(val) && _.trim(val) !== '';

const isAddressVisible = ctx => {
  return ctx.person.isAddressVisible;
};

const getDefaultPeriod = formContext => {
  const periodMapping = {
    threeMonths: '3',
    sixMonths: '6',
    nineMonths: '9',
    twelveMonths: '12'
  };
  return periodMapping[formContext.defaultPeriod];
};

const onFrequencyChange = (formContext, ctx, setValues) => {
  const frequency = _.get(ctx, ['data', 'frequency']) || formContext.defaultFrequency;
  const frequencyDefinition = formContext.frequencies.find(f => f.value === frequency);
  const showIcon = formContext.amountDisplay === 'icon';
  ctx.setList('amounts', frequencyDefinition.amounts.map(a => ({
    label: showIcon ? a.label : a.name,
    value: {
      value: a.amount_m.toString(),
      icon: showIcon ? a.icon : null,
      ribbon: a.amount_m === frequencyDefinition.defaultAmount_m ? frequencyDefinition.defaultRibbon : ''
    }
  })));

  let theSelectedAmount = frequencyDefinition.amounts.find(a => a.amount_m === frequencyDefinition.defaultAmount_m);

  if (!theSelectedAmount && frequencyDefinition.amounts.length === 1) {
    theSelectedAmount = frequencyDefinition.amounts[0];
  }

  const values = {
    isCustomAmountVisible: formContext.customAmountDisplay === 'inline' && frequencyDefinition.allowCustomAmount,
    allowCustomAmount: frequencyDefinition.allowCustomAmount,
    amountText: theSelectedAmount ? theSelectedAmount.message : null,
    amountLabel: theSelectedAmount ? theSelectedAmount.label : null,
    customAmount_m: null,
    amount_m: frequencyDefinition.defaultAmount_m || null,
    paymentMethod: frequency === 'oneOff' ? formContext.paymentMethods[0].value : 'directDebit'
  };

  if (formContext.mandateType === 'temporary' && frequencyDefinition.value === 'monthly') {
    values.period = getDefaultPeriod(formContext);
  } else if (formContext.mandateType !== 'temporary' || frequencyDefinition.value !== 'monthly') {
    values.period = null;
  }

  setValues(values);
};

const onAddressChange = (formContext, ctx, cleanse, callback) => {
  const postalCode = ctx.data.postalCode;
  const houseNumber = ctx.data.houseNumber_i;
  const houseNumberAddition = ctx.data.houseNumberAddition;
  if (hasValue(postalCode) && hasValue(houseNumber)) {

    // if we are already cleansing do not start again, request may interfer
    cleanse({
      postalCode,
      houseNumber_i: houseNumber,
      houseNumberAddition
    }, (data) => {
      if (data.status === 500) {
        data = {
          result: {
            success: true
          },
          address: {
            street: '',
            city: ''
          }
        };
      }

      const addressCleansingStatus = _.get(data, ['result', 'success'], false) ? 'GREEN' : 'RED';
      const cleansedAddress = _.get(data, ['result', 'address']);

      if (addressCleansingStatus === 'GREEN') {
        ctx.setValues({
          street: cleansedAddress.street,
          city: cleansedAddress.city,
          postalCode: cleansedAddress.postalCode,
          houseNumber_i: cleansedAddress.houseNumber_i,
          houseNumberAddition: cleansedAddress.houseNumberAddition,
          addressCleansingStatus
        });
      } else {
        ctx.setValues({
          street: null,
          city: null,
          addressCleansingStatus
        });
      }

      ctx.validate(true);
      return callback();
    });
  } else if (!hasValue(postalCode) || !hasValue(houseNumber)) {
    ctx.setValues({
      addressCleansingStatus: null,
      street: null,
      city: null
    });
    return callback();
  }
};

const onEmailChange = (formContext, ctx) => {
  const email = _.get(ctx, ['data', 'email']);
  const previousEmail = _.get(ctx, ['previousData', 'email']);

  if (hasValue(email) && email !== previousEmail) {
    ctx.setValues({
      email: _.trim(email)
    });
  }
};

const onCustomAmountChange = (formContext, ctx) => {
  ctx.setValues({
    amount_m: null
  });
};

const onAmountChange = (formContext, ctx) => {
  ctx.setValues({
    customAmount_m: null
  });
};

const createFormConfig = ({formContext, cleansing, cleanse, resetSubmittingForm, save, history}) => {
  return {
    onSubmit: (data) => {
      if (cleansing) {
        resetSubmittingForm();
      } else {
        save(history, data);
      }
    },
    onInit: (ctx) => {
      ctx.data = {...ctx}; // hack to reuse change handlers since onDataChanged receives a context where the data is nested in a data property

      ctx.setDefaultValues({
        allowNewsletter: _.get(formContext, 'newsletterDefault', false),
        allowTelemarketing: formContext.telemarketingOptIn !== 'none' && _.get(formContext, 'telemarketingDefault', true)
      });

      if (formContext.showDonationFieldsQuestionVisible === true) {
        const selectedFrequency = ctx.frequency || formContext.defaultFrequency;
        const frequencyDefinition = formContext.frequencies.find(f => f.value === selectedFrequency);
        const customAmountDisplay = _.get(formContext, ['customAmountDisplay']);
        const showInline = customAmountDisplay === 'inline' && frequencyDefinition.allowCustomAmount;

        const defaultValues = {
          optInAdminCostContribution: formContext.askAdminCostContribution === true ? _.get(formContext, 'adminCostContributionDefault') : false,
          adminCostContributionAmount_m: formContext.adminCostContributionAmount_m,
          frequency: frequencyDefinition ? frequencyDefinition.value : null,
          authorizeLabel: ctx.donateAsOrganisation ? formContext.privacyConfirmationTextOrganisation : formContext.privacyConfirmationTextPerson
        };

        ctx.setList('days', _.range(1, 31).map(value => ({label: `${value}`, value: `${value}`})));
        ctx.setList('genders', genders(formContext));
        ctx.setList('idealBanks', formContext.idealBanks);
        ctx.setList('creditCardBanks', formContext.creditCardBanks);
        ctx.setList('paymentMethods', formContext.paymentMethods);
        ctx.setList('frequencies', formContext.frequencies);
        if (formContext.mandateType === 'temporary') {
          const periods = [];
          if (formContext.allowPeriodThreeMonths === true) {
            periods.push({label: 'Stopt automatisch na 3 maanden', value: '3'});
          }
          if (formContext.allowPeriodSixMonths === true) {
            periods.push({label: 'Stopt automatisch na 6 maanden', value: '6'});
          }
          if (formContext.allowPeriodNineMonths === true) {
            periods.push({label: 'Stopt automatisch na 9 maanden', value: '9'});
          }
          if (formContext.allowPeriodTwelveMonths === true) {
            periods.push({label: 'Stopt automatisch na 12 maanden', value: '12'});
          }
          ctx.setList('periods', periods);
          ctx.setDefaultValues({
            period: getDefaultPeriod(formContext)
          });
        }
        ctx.setDefaultValues(defaultValues);
        onFrequencyChange(formContext, ctx, ctx.setDefaultValues);
        ctx.setValues({
          isCustomAmountVisible: showInline || ctx.data.isCustomAmountVisible
        });
      }
    },
    onDataChanged: (ctx, callback) => {
      switch (ctx.field) {
        case 'postalCode':
        case 'houseNumber_i':
        case 'houseNumberAddition':
          return onAddressChange(formContext, ctx, cleanse, callback);
        case 'email':
          onEmailChange(formContext, ctx);
          break;
        case 'frequency':
          onFrequencyChange(formContext, ctx, ctx.setValues);
          break;
        case 'amount_m':
          onAmountChange(formContext, ctx);
          break;
        case 'customAmount_m':
          onCustomAmountChange(formContext, ctx);
          break;
        default:
          break;
      }

      return callback();
    },
    fields: {
      telemarketingInfoText: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'text' && ctx.telemarketingOptIn !== 'button') {
            return false;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      allowTelemarketing: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'checkbox') {
            return false;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      email: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.email) && ctx.email !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.email) || ctx.email === '') {
                return true;
              }

              return /^[-0-9a-zA-Z.+_]{1,63}@([-0-9a-zA-Z+_]+\.){1,63}[a-zA-Z]{1,63}$/.test(_.trim(ctx.email));
            },
            message: 'Er is geen geldig e-mailadres ingevuld.'
          }
        ]
      },
      gender: {
        isVisible: ctx => ctx.person.isGenderVisible === true,
        isRequired: ctx => ctx.person.isGenderRequired === true,
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isGenderRequired) {
                return !_.isNil(ctx.gender) && _.trim(ctx.gender) !== '';
              }

              return true;
            }
          }
        ]
      },
      initials: {
        isVisible: ctx => ctx.person.isInitialsVisible,
        isRequired: ctx => ctx.person.isInitialsRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isInitialsRequired) {
                return !_.isNil(ctx.initials) && _.trim(ctx.initials) !== '';
              }

              return true;
            }
          }
        ]
      },
      firstName: {
        isVisible: ctx => ctx.person.isFirstNameVisible,
        isRequired: ctx => ctx.person.isFirstNameRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isFirstNameRequired) {
                return !_.isNil(ctx.firstName) && _.trim(ctx.firstName) !== '';
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.firstName) || ctx.firstName === '') {
                return true;
              }

              return _.trim(ctx.firstName).length > 1;
            },
            message: 'Voornaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      infix: {},
      lastName: {
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.lastName) && ctx.lastName !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.lastName) || ctx.lastName === '') {
                return true;
              }

              return _.trim(ctx.lastName).length > 1;
            },
            message: 'Achternaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      postalCode: {
        isVisible: isAddressVisible,
        isRequired: ctx => {
          if (hasValue(ctx.houseNumber_i) || hasValue(ctx.houseNumberAddition)) {
            return true;
          }

          return ctx.person.isAddressRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (!hasValue(ctx.postalCode)) {
                return true;
              }

              return /^[1-9][0-9]{3}\W?[a-zA-Z]{2}$/.test(_.trim(ctx.postalCode));
            },
            message: 'Er is geen geldige postcode ingevuld.'
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.houseNumber_i) || hasValue(ctx.houseNumberAddition)) {
                return hasValue(ctx.postalCode);
              }

              if (ctx.person.isAddressRequired) {
                return hasValue(ctx.postalCode);
              }

              return true;
            }
          },
          {
            message: 'Het opgegeven adres kan niet worden gevonden.',
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          }
        ]
      },
      houseNumber_i: {
        isVisible: isAddressVisible,
        isRequired: ctx => {
          if (hasValue(ctx.postalCode) || hasValue(ctx.houseNumberAddition)) {
            return true;
          }

          return ctx.person.isAddressRequired;
        },
        rules: [
          {
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.houseNumber_i)) {
                return true;
              }

              return ctx.houseNumber_i.length < 8 && /^\d*$/.test(ctx.houseNumber_i);
            },
            message: 'Er is geen geldig huisnummer ingevuld. Controleer of er alleen cijfers gebruikt zijn.'
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.postalCode) || hasValue(ctx.houseNumberAddition)) {
                return hasValue(ctx.houseNumber_i);
              }

              if (ctx.person.isAddressRequired) {
                return hasValue(ctx.houseNumber_i);
              }

              return !(ctx.addressCleansingStatus === 'RED' && !_.isNil(ctx.houseNumber_i));
            }
          }
        ]
      },
      houseNumberAddition: {
        isVisible: isAddressVisible
      },
      telephone: {
        isVisible: ctx => ctx.person.isTelephoneVisible,
        isRequired: ctx => ctx.person.isTelephoneRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isTelephoneRequired) {
                return hasValue(ctx.telephone);
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.telephone)) {
                return true;
              }

              return formatPhone(ctx.telephone).result === 'GREEN';
            },
            message: 'Er is geen geldig telefoonnummer ingevuld.'
          }
        ]
      },
      dateOfBirth: {
        isVisible: ctx => ctx.person.isDateOfBirthVisible,
        isRequired: ctx => ctx.person.isDateOfBirthRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.person.isDateOfBirthRequired) {
                return hasValue(ctx.dateOfBirth);
              }

              return true;
            }
          }
        ]
      },
      street: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      city: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      allowNewsletter: {
        isVisible: () => formContext.allowNewsletter === true
      },
      variableCheckboxAnswer: {
        isVisible: ctx => ctx.variableCheckboxVisibility === 'show'
      },
      variableFieldAnswer: {
        isVisible: ctx => ctx.isVariableFieldVisible,
        isRequired: ctx => ctx.isVariableFieldRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.isVariableFieldRequired) {
                return !_.isNil(ctx.variableFieldAnswer) && _.trim(ctx.variableFieldAnswer) !== '';
              }

              return true;
            }
          }
        ]
      },
      showDonationFields: {
        isVisible: () => formContext.showDonationFieldsQuestionVisible === true,
        isRequired: () => formContext.showDonationFieldsQuestionVisible === true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.showDonationFields)
          }
        ]
      },
      frequency: {
        isVisible: ctx => ctx.showDonationFields === true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.frequency)
          }
        ]
      },
      allowCustomAmount: {
        // always visible so it's stored in the session, but it's not actually shown as a field on the page
        isVisible: () => true
      },
      isCustomAmountVisible: {
        // always visible so it's stored in the session, but it's not actually shown as a field on the page
        isVisible: () => true
      },
      setCustomAmountVisible: {
        isVisible: ctx => ctx.showDonationFields === true && formContext.customAmountDisplay !== 'inline' && ctx.allowCustomAmount === true && ctx.isCustomAmountVisible !== true
      },
      setCustomAmountHidden: {
        isVisible: ctx => ctx.showDonationFields === true && formContext.customAmountDisplay !== 'inline' && ctx.allowCustomAmount === true && ctx.isCustomAmountVisible === true
      },
      customAmount_m: {
        isVisible: (ctx) => ctx.showDonationFields === true && ctx.isCustomAmountVisible === true,
        rules: [
          {
            isValid: ctx => {
              if (!ctx.isCustomAmountVisible) {
                return true;
              }

              const amount = _.get(ctx, ['amount_m']);
              const amountHasValue = amount !== '' && !_.isNil(amount);

              if (amountHasValue && formContext.customAmountDisplay === 'inline') {
                return true;
              }

              const frequency = formContext.frequencies.find(f => f.value === _.get(ctx, ['frequency']));

              const maxAmount = BigNumber(frequency.maxAmount_m || '100000');
              const minAmount = BigNumber(frequency.minAmount_m || '1');

              const amountBn = parseAndGetBigNumber(ctx.customAmount_m);

              if (_.isNil(amountBn) || amountBn.isNaN()) {
                return true;
              }

              return amountBn.isGreaterThanOrEqualTo(minAmount) && amountBn.isLessThanOrEqualTo(maxAmount);
            },
            message: ctx => {
              const frequency = formContext.frequencies.find(f => f.value === _.get(ctx, ['frequency']));

              const maxAmount = BigNumber(frequency.maxAmount_m || '100000');
              const minAmount = BigNumber(frequency.minAmount_m || '1');

              return `Vul een bedrag in tussen € ${minAmount.toFormat(2)} en € ${maxAmount.toFormat(2)}`;
            }
          },
          {
            isValid: ctx => {
              if (!ctx.isCustomAmountVisible) {
                return true;
              }

              const amount = _.get(ctx, ['amount_m']);
              const amountHasValue = amount !== '' && !_.isNil(amount);

              if (amountHasValue && formContext.customAmountDisplay === 'inline') {
                return true;
              }

              const amountBn = parseAndGetBigNumber(ctx.customAmount_m);
              return !_.isNil(amountBn) && !amountBn.isNaN();
            }
          }
        ]
      },
      amountText: {
        isVisible: ctx => ctx.showDonationFields === true && !_.isNil(ctx.amountText)
      },
      amountConfirmationText: {
        isVisible: ctx => ctx.showDonationFields === true && !_.isNil(ctx.amount_m) && !_.isNil(ctx.frequency)
      },
      amount_m: {
        isVisible: ctx => {
          if (ctx.showDonationFields === true) {
            return formContext.customAmountDisplay === 'toggle' ? ctx.isCustomAmountVisible !== true : true;
          }
          return false;
        },
        rules: [
          {
            isValid: ctx => {
              const amountHasValue = !_.isNil(ctx.amount_m) && ctx.amount_m !== '';
              const customAmountHasValue = !_.isNil(ctx.customAmount_m) && ctx.customAmount_m !== '';

              return amountHasValue || customAmountHasValue;
            }
          }
        ]
      },
      paymentMethod: {
        isVisible: ctx => ctx.showDonationFields === true && ctx.frequency === 'oneOff',
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.paymentMethod)
          }
        ]
      },
      iban: {
        isVisible: ctx => ctx.showDonationFields === true && ctx.paymentMethod === 'directDebit',
        isRequired: ctx => ctx.paymentMethod === 'directDebit',
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.iban) && ctx.iban !== ''
          },
          {
            message: 'Vul hier een geldig IBAN nummer in.',
            isValid: ctx => {
              var value = ctx.iban;

              if (_.isNil(value)) {
                return true;
              }

              return IBAN.isValid(value);
            }
          },
          {
            message: 'IBAN moet afkomstig zijn uit een SEPA land',
            isValid: ctx => {
              const value = ctx.iban;
              if (_.isNil(value) || value === '') return true;

              return isSepaCountryIban(value);
            }
          }
        ]
      },
      bank: {
        isVisible: ctx => ctx.showDonationFields === true && ctx.paymentMethod === 'ideal' && !formContext.hideBankOptions,
        isRequired: ctx => ctx.paymentMethod === 'ideal' && !formContext.hideBankOptions,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.bank) && ctx.bank !== ''
          }
        ]
      },
      creditCardBank: {
        isVisible: ctx => ctx.showDonationFields === true && ctx.paymentMethod === 'creditCard',
        isRequired: ctx => ctx.paymentMethod === 'creditCard',
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.creditCardBank) && ctx.creditCardBank !== ''
          }
        ]
      },
      authorize: {
        isVisible: ctx => ctx.showDonationFields === true,
        isRequired: ctx => ctx.showDonationFields === true,
        rules: [
          {
            isValid: ctx => {
              if (ctx.showDonationFields === true) {
                return ctx.authorize === true;
              }
              return true;
            }
          }
        ]
      }
    }
  };
};

export default createFormConfig;
